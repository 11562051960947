import { IButterEvent, IButterVenue, IEvent, IVenue } from '@/types/cms';
import { getFacetCode } from '@lamesarv-sdk/tools';

export const parseVenue = (venue: IButterVenue): IVenue => {
  return {
    title: venue.title,
    slug: venue.slug,
    descriptionSEO: venue.description_seo,
    features: venue.features,
    image: venue.image,
    city: venue.city,
    state: venue.state,
    zipCode: venue.zip_code,
    map: venue.map,
    mapEmbedded: venue.map_embedded,
    headerClasses: venue.header_classes,
    headerBackgroundUrl: venue.header_image_url,
  };
};

export const parseEvent = (event: IButterEvent): IEvent => {
  return {
    title: event.title,
    slug: event.slug,
    descriptionSEO: event.description_seo,
    description: event.description,
    image: event.image_url,
    startDate: event.start_date,
    closedDate: event.closed_date,
    endDate: event.end_date,
    cost: event.cost,
    venue: parseVenue(event.venue),
    headerClasses: event.header_classes,
    headerBackgroundUrl: event.header_image_url,
  };
};

export const searchEvents = (events: IEvent[], query: string, states: string[], cities: string[]): IEvent[] => {
  const eventFields = ['title', 'slug', 'descriptionSEO'];
  const venueFields = ['title', 'slug', 'descriptionSEO', 'city', 'state', 'zipCode'];

  const matchesQuery = (field: string, query: string) => {
    return field.toLowerCase().includes(query.toLowerCase());
  };

  return events.filter((event) => {
    if (states.length > 0 && !states.includes(getFacetCode(event.venue.state))) return false;
    if (cities.length > 0 && !cities.includes(getFacetCode(event.venue.city))) return false;

    if (!query) return true;

    const eventMatch = eventFields.some((field) => matchesQuery(event[field], query));
    const venueMatch = venueFields.some((field) => matchesQuery(event.venue[field], query));

    return eventMatch || venueMatch;
  });
};
