import { SyntheticEvent, useEffect, useState } from 'react';
import { faCalendarCheck, faChevronDown, faMapLocationDot, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useGetEvents, useGetVenues } from '@/hooks/events';
import { Button, ButtonType, GenericHtml, Spinner } from '@lamesarv-sdk/components';

import { IComponentBase, PropsWithClasses } from '@lamesarv-sdk/types';
import { IEvent } from '@/types/cms';
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { getFacetCode } from '@lamesarv-sdk/tools';
import { searchEvents } from '@/tools/events';

export interface IEventSearchProps {
  callbackSearch: (query: string) => void;
  placeholder?: string;
}

export const EventSearch = (props: IEventSearchProps) => {
  const handleSearch = async (e: SyntheticEvent) => {
    e.preventDefault();
    const value = ((e.target as HTMLFormElement).query as HTMLInputElement).value;

    props.callbackSearch(value);
  };

  return (
    <form className="w-full flex flex-row" onSubmit={handleSearch}>
      <input
        type="search"
        name="query"
        placeholder={props.placeholder ?? 'Search'}
        className="w-full py-2 px-4 border border-gray-200 border-r-0 rounded-sm focus:ring-gray-400"
      />
      <Button
        type={ButtonType.flat}
        icon={<FontAwesomeIcon icon={faMagnifyingGlass} className="w-4 h-4 text-white" />}
        htmlType="submit"
        title="Search"
        className="bg-neutral-900 text-white py-2 px-4 rounded-l-none rounded-r-sm hover:bg-neutral-800 focus:outline-none"
      />
    </form>
  );
};

export const EventItem = (props: IEvent) => {
  const eventStartDate = new Date(props.startDate);
  const eventEndDate = new Date(props.endDate);

  const isExpired = eventEndDate < new Date();

  return (
    <div
      className={twMerge(
        'grid grid-cols-1 gap-8 items-center rounded-sm drop-shadow-sm p-6 border border-gray-200 md:grid-cols-4',
        isExpired && 'bg-gray-50',
      )}
    >
      <div className="col-span-1">
        <div className="flex flex-row gap-2 items-center">
          <FontAwesomeIcon icon={faCalendarCheck} className="w-10 h-10 text-gray-300" />
          <div className="flex flex-col">
            <div className="text-lg font-bold">
              {eventStartDate.toLocaleDateString('en-US', { weekday: 'long' })}{' '}
              {eventStartDate.toLocaleDateString('en-US', { day: '2-digit' })}
            </div>
            <div className="text-gray-600">
              {eventStartDate.toLocaleDateString('en-US', { month: 'long' })}{' '}
              {eventStartDate.toLocaleDateString('en-US', { year: 'numeric' })}
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-2">
        <h2 className="text-xl font-bold mb-2">
          {isExpired && <span className="text-black">{props.title}</span>}
          {!isExpired && (
            <a
              href={`/events/${props.slug}`}
              className="underline-offset-4 decoration-dashed	text-black hover:underline"
            >
              {props.title}
            </a>
          )}
        </h2>
        <div className="flex flex-row gap-2 items-center mb-2">
          <FontAwesomeIcon icon={faMapLocationDot} className="w-10 h-10 text-gray-300 group-hover:text-black" />
          <div className="flex flex-col">
            <p className="text-sm text-gray-600">
              {props.venue.city}, {props.venue.state}
            </p>
            <p className="text-sm font-bold">{props.venue.title}</p>
          </div>
        </div>
        <GenericHtml content={props.venue.features} />
        {!isExpired && (
          <div className="flex flex-row gap-1 text-black mt-2">
            <span className="uppercase text-black font-bold">Cost</span>
            <span className="">{props.cost ? `${props.cost.toLocaleString()} USD` : 'Free'}</span>
          </div>
        )}
      </div>
      <div className="col-span-1">
        <img
          src={props.image}
          alt="Event Image"
          className={twMerge('w-full object-cover rounded-sm', isExpired && 'grayscale')}
        />
      </div>
    </div>
  );
};

export interface IEventFilterProps {
  title: string;
  items: { label: string; value: string }[];
  itemsCurrent: string[];
  callbackSelect: (values: string[]) => void;
}

export const EventFilter = (props: IEventFilterProps) => {
  const handleSelect = (e: SyntheticEvent) => {
    const value = (e.target as HTMLInputElement).value;

    if (props.itemsCurrent.includes(value)) {
      props.callbackSelect(props.itemsCurrent.filter((item) => item !== value));
    } else {
      props.callbackSelect([...props.itemsCurrent, value]);
    }
  };

  return (
    <Popover className="relative border py-2.5 px-3 min-w-24 bg-white">
      <PopoverButton className="w-full flex flex-row gap-1 items-center justify-between text-sm font-medium focus:outline-none">
        <span>{props.title}</span>
        <FontAwesomeIcon icon={faChevronDown} className="w-4 h-4 text-gray-300" />
      </PopoverButton>
      <PopoverPanel className="absolute right-0 z-10 mt-4 origin-top-right rounded-md bg-white p-4 shadow-2xl transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
        <div className="flex flex-col gap-2">
          {!props.items.length && (
            <span className="text-sm font-medium text-gray-300 whitespace-nowrap">No items available</span>
          )}
          {props.items.map((item, index) => (
            <label key={index} className="flex flex-row gap-2 items-center">
              <input
                type="checkbox"
                value={item.value}
                checked={props.itemsCurrent.includes(item.value)}
                onChange={handleSelect}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <span className="text-sm font-medium text-gray-900 capitalize whitespace-nowrap">{item.label}</span>
            </label>
          ))}
        </div>
      </PopoverPanel>
    </Popover>
  );
};

export interface IComponentEventsList extends IComponentBase, PropsWithClasses {
  lastRecent: number;
  filterByState?: string;
  displaySearch?: boolean;
  displayFilterState?: boolean;
  displayFilterCity?: boolean;
  classHeader?: string;
}

export const EventList = (props: IComponentEventsList) => {
  const { items: venues } = useGetVenues({});
  const { isLoading, fetchItems: fetchEvents } = useGetEvents({ skipFetch: true });

  const [query, setQuery] = useState<string>('');
  const [selectedStates, setSelectedStates] = useState<string[]>(
    props.filterByState ? [getFacetCode(props.filterByState)] : [],
  );
  const [selectedCities, setSelectedCities] = useState<string[]>([]);

  const [items, setItems] = useState<IEvent[]>([]);

  const states = venues
    .map((venue) => {
      return {
        label: venue.state,
        value: getFacetCode(venue.state),
      };
    })
    .filter(() => Boolean);

  const cities = venues.reduce((acc: { label: string; value: string }[], venue) => {
    if (
      selectedStates.includes(getFacetCode(venue.state)) &&
      !acc.find((item) => item.value === getFacetCode(venue.city))
    ) {
      acc.push({
        label: venue.city,
        value: getFacetCode(venue.city),
      });
    }

    return acc;
  }, []);

  const getItems = async () => {
    const baseItems = await fetchEvents({
      amount: query ? 1000 : props.lastRecent || 100,
      skipDateFilter: !!query,
    });
    const parsedItems = searchEvents(baseItems, query, selectedStates, selectedCities);

    setItems(parsedItems);
  };

  useEffect(() => {
    getItems();
  }, [query, selectedStates, selectedCities]);

  return (
    <div className="flex flex-col">
      <div className={twMerge('bg-neutral-100 border-b border-neutral-200', props.classHeader)}>
        <div className="max-w-7xl space-y-12 mx-auto p-4 md:p-8">
          <div className="flex flex-row gap-4 justify-between items-center">
            {props.displaySearch && (
              <EventSearch callbackSearch={(newQuery: string) => setQuery(newQuery)} placeholder="Search Events" />
            )}
            {(props.displayFilterState || props.displayFilterCity) && (
              <PopoverGroup className="flex flex-row gap-4 items-center">
                {props.displayFilterState && (
                  <EventFilter
                    title="States"
                    items={states}
                    itemsCurrent={selectedStates}
                    callbackSelect={(newSelectedStates: string[]) => {
                      setSelectedStates(newSelectedStates);
                      setSelectedCities([]);
                    }}
                  />
                )}
                {props.displayFilterCity && (
                  <EventFilter
                    title="Cities"
                    items={cities}
                    itemsCurrent={selectedCities}
                    callbackSelect={(newSelectedStates: string[]) => setSelectedCities(newSelectedStates)}
                  />
                )}
              </PopoverGroup>
            )}
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="flex flex-row justify-center items-center h-96">
          <Spinner />
        </div>
      )}
      <div className={twMerge('max-w-7xl space-y-12 mx-auto p-4 md:p-8', props.classList)}>
        {items.map((item, index) => (
          <EventItem key={index} {...item} />
        ))}
      </div>
    </div>
  );
};
