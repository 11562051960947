import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useGetVenues } from '@/hooks/events';

import { GenericHtml } from '@lamesarv-sdk/components';

import { IVenue } from '@/types/cms';
import { IComponentBase } from '@lamesarv-sdk/types';

export const VenueItem = (props: IVenue) => {
  return (
    <div className="grid grid-cols-1 rounded-sm drop-shadow-sm p-6 border border-gray-1 md:grid-cols-4 gap-8 items-start">
      <div className="col-span-3">
        <div className="flex flex-row gap-2 items-center mb-2">
          <FontAwesomeIcon icon={faMapLocationDot} className="w-10 h-10 text-gray-300 group-hover:text-black" />
          <div className="flex flex-col">
            <h2 className="text-xl font-bold">
              <a
                href={`/locations/${props.slug}`}
                className="underline-offset-4 decoration-dashed	text-black hover:underline"
              >
                {props.title}
              </a>
            </h2>
            <p className="text-gray-600">
              {props.city}, {props.state}
            </p>
          </div>
        </div>
        <GenericHtml content={props.features} />
      </div>
      {props.image && (
        <div className="col-span-1">
          <img src={props.image} alt="Event Image" className="w-full object-cover rounded-sm" />
        </div>
      )}
    </div>
  );
};

export interface IComponentVenuesList extends IComponentBase {
  exclude?: string[];
  displaySearch?: boolean;
}

export const VenuesList = (props: IComponentVenuesList) => {
  const { items } = useGetVenues({});

  const itemsParsed = items.filter((item) => !(props.exclude ?? []).includes(item.slug));

  return (
    <div className="px-8">
      <div className="max-w-7xl space-y-12 mx-auto py-8">
        {itemsParsed.map((item, index) => (
          <VenueItem key={index} {...item} />
        ))}
      </div>
    </div>
  );
};
