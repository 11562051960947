import { useState, useEffect } from 'react';

import { useRequest } from '@lamesarv-sdk/hooks';

import { IEvent, IVenue } from '@/types/cms';

interface IGetEventsProps {
  amount?: number;
  venue?: string;
  city?: string;
  state?: string;
  skipFetch?: boolean;
  skipDateFilter?: boolean;
  callback?: (data: IEvent[]) => void;
}

export const useGetEvents = (props: IGetEventsProps) => {
  const [items, setItems] = useState<IEvent[]>([]);
  const { hasError, isLoading, sendRequest } = useRequest<IEvent[]>(`/api/events/getEvents`, {
    method: 'post',
  });

  const fetchItems = async (fetchProps?: IGetEventsProps): Promise<IEvent[]> => {
    const _props = fetchProps ?? props;
    const data = await sendRequest(_props);

    if (!data) return;

    setItems(data);

    if (_props.callback) {
      _props.callback(data);
    }

    return data;
  };

  useEffect(() => {
    if (!props.skipFetch) {
      fetchItems();
    }
  }, []);

  return {
    isLoading,
    hasError,
    items,
    fetchItems,
  };
};

interface IGetVenuesProps {
  city?: string;
  state?: string;
  skipFetch?: boolean;
  callback?: (data: IVenue[]) => void;
}

export const useGetVenues = (props: IGetVenuesProps) => {
  const [items, setItems] = useState<IVenue[]>([]);
  const { hasError, isLoading, sendRequest } = useRequest<IVenue[]>(`/api/events/getVenues`, {
    method: 'post',
  });

  const fetchItems = async (fetchProps?: IGetVenuesProps): Promise<IVenue[]> => {
    const _props = fetchProps ?? props;
    const data = await sendRequest(_props);

    if (!data) return;

    setItems(data);

    if (_props.callback) {
      _props.callback(data);
    }

    return data;
  };

  useEffect(() => {
    if (!props.skipFetch) {
      fetchItems();
    }
  }, []);

  return {
    isLoading,
    hasError,
    items,
    fetchItems,
  };
};
